body {
  font-family: 'Roboto Mono', monospace !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scroll-behavior: smooth !important;
  background-color:black !important;
  color:#00e611;
  text-align: justify !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

html{
  scroll-behavior: smooth !important;
}
