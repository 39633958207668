body{
    scroll-behavior:smooth;
    font-family: 'Open Sans', sans-serif;
    font-family: 'Roboto', sans-serif;
    scroll-behavior: smooth !important;
    color:#00e611;
}

:root{
    --customDark:#00e611;
}

img{
    height:auto;
    margin:auto;
    width:180px;
    height:180px;
    border-radius:50%;
    margin-bottom:30px;
}

.certLink:hover{
    color:#00e611;
}

.certLink1:hover{
    color:#00e611;

}

.App-header{
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
}

.iconStyle{
    color:#00e611 !important;
    background-color:none !important;
    border-radius:8px;
    margin:5px;
    background-size:100%;
    transition: 1.1s;
}

.iconStyle:hover{
    background-color:none;
    position:relative;
    top:-12px;
    box-shadow:10px 130px 130px #00e611;
    background-size:110%;
}

.welcomeHello{
    font-family: 'Open Sans', sans-serif;
    font-family: 'Roboto', sans-serif;
    font-size:4rem;
}


.mediaBody{
  padding-bottom:50px;
}

.intro{
    margin-top:180px;
}

.githubLink{
    text-decoration: none !important;
    color: rgba(0,0,0,.5);

}

.santaCruzLink:hover{
        text-decoration: none !important;
        color:#3dff4a;
}

.santaCruzLink{
    text-decoration: none !important;
    color:#00e611;
}

.githubLink:hover{
    color:var(--customDark);
}

.introLayout{
    color:#00e611;
    margin-top:20px;
}

.GetInTouchStyle{
    background-color:black;
    border:1.5px solid #00e611;
    color:#00e611;
    padding:10px;
    border-radius:6px;
}

.GetInTouchStyle:hover{
    background-color:#00e611;
    color:black;
    border:1px solid #00e611;
    padding:10px;
    border-radius:6px;
}

.introStyle{
    margin-top:100px;
}

.titleSentence{
    color:   #00e611;
}

.aboutMeLayout{
    display:flex;
    flex-direction:column;
    justify-content: center;
    align-content: center;
    text-align: center;
    font-family: 'Open Sans', sans-serif;
    font-family: 'Roboto', sans-serif;
    padding-left:50px;
    padding-right:50px;
}

.row{
    margin:auto;
}

.aboutMeLine{
    margin-top:200px;
    padding-bottom:50px;
}

h3{
    font-size: 20px;
    position: relative;
    overflow: hidden;
    text-align:left;
    font-family: 'Open Sans', sans-serif;
    font-family: 'Roboto', sans-serif;
}

h3:before {
    display: inline-block;
    content: "";
    height:.5px;
    background:    #00e611;
    position: absolute;
    width: 100%;
    top: 50%;
    margin-top: 2px;
    margin-left: 140px;
    font-family: 'Open Sans', sans-serif;
    font-family: 'Roboto', sans-serif;
}

.tableSkill{
    color:black;
    margin:auto;
    width:100%;
    background-color:   #00e611;
    border-style:1.5px solid black !important;
}
td{
    color:black ;
    text-align:center;
    border-style:1.5px solid black !important;
}

a{
    color:#00e611;
}

a:hover{
    color:#6efd78;
    text-decoration:none;
}

tr{
    color:black;
    border-style:1.5px solid black !important;
}

.navStyle{
    background-color:#00e611 !important;
    color:black;
    box-shadow: 0px 3px 3px black;
    position:fixed;
    width:100%;
    top:0px;
    left:0px;
    z-index:1 !important;
    border-style:1.5 solid black !important;
}

.hamburgerStyle{
    outline:none;
}
.hamburgerStyle:focus{
    outline:none;

}

.projectClass{
    padding-left:60px;
    padding-right:60px;
    margin-bottom:20px;
    margin:auto;
    margin-top:60px;
  
}

.foot{
    margin-top:260px;
    height:50px;
    margin-bottom:30px;
    
}

.footArrow:hover{
    color:   #00e611;
    border-radius:25px;
    position:relative;
    top:-1px;
    box-shadow:0px 35px 35px    #00e611;
    
}
