
.cardStyle{
    /* box-shadow:6px 10px 10px rgba(45,45,45,.8); */
    border:3.5px solid    #00e611;
    border-radius:15px;
    background-size:100% !important;
    transition:1.1s !important;
    color:   black;
    background-color:black;
    background-size:100%;
    transition: 1.1s;
}

.cardStyle:hover{
    background-size:110% !important;
    position:relative;
    top:-14px;
    background-color:   black;
    color:black;
    border-style:3.5px solid #00e611 !important;
    box-shadow:0px 50px 50px    #00e611;
    background-size:110%;
}


/* background-color:none;
position:relative;
top:-12px;
box-shadow:6px 15px 10px rgba(45,45,45,.5); */

.imageStyle{
    background-size:100% !important;
    transition:0.5s !important;
}

.imageStyle:hover{
    background-size:110% !important;
}

.cardStyle:hover{
    background-color:   black;
    color:   #00e611;
    border:3.5px solid #00e611;
}


/* a:hover{
    color:    black !important;
}


a{
    text-decoration: none !important;
    color:   #00e611;
} */