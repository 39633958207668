:root{
  --customCyan:#61dafb;
}

.body{
  scroll-behavior: smooth;
  background-color:black;
  font-family: 'Roboto Mono', monospace;
}

.App {
  text-align: center;
  
}

.table-bordered td, .table-bordered th {
  border: 1px solid #000000;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}


